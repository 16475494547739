<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					쿠폰함
				</span>
			</p>
			<a class="nav__btn">
				<i class="icon icon-ticket--white">포인트</i>
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<span>
								<strong>인기많은만두</strong>님의<br>
								사용가능한 쿠폰
							</span>
						</div>
					</div>
					<div class="mypage__my-point">
						<small>
							내 쿠폰
						</small>
						<strong>3</strong>장
					</div>
				</div>
			</section>
			<section class="content">
				<div class="box">
					<div class="mypage__wrap">
						<div class="mypage__inner">
							<!-- 마이페이지 쿠폰 상세 -->
							<div class="mypage__coupondetail">
								<!-- 쿠폰 등록하기 -->
								<button type="button" class="btn btn-couponregister">
									<span>
										<i class="icon icon-ticket--point"></i>
									</span>
									<strong>
										쿠폰 등록하기
									</strong>
									<span>
										<i class="icon icon-rightbig--blur"></i>
									</span>
								</button>
								<!-- 쿠폰 탭 -->
								<div class="tab tab--half">
									<a href="#" class="active">사용가능</a>
									<a href="#">사용완료</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="textbox textbox--margin">
					<div class="textbox__icon">
						<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
					</div>
					<h3 class="textbox__title">사용 가능한 <br>쿠폰 내역이 없어요</h3>
					<p class="textbox__description">
						쿠폰 발급 이벤트에 참여하고 <br>
						쿠폰을 받으실 수 있습니다.
					</p>
				</div>
			</section>
		</div>
	</div>
</template>

